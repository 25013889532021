import { TenantDetails } from "./TenantDetails";

export class Home{
    phase1Name:string;
    phase1Status:string;
    phase2Name:string;
    phase2Status:string;
    phase3Name:string;
  phase3Status: string;
  tenantDetails: TenantDetails;
}

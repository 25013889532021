import { Pipe, PipeTransform } from '@angular/core';
import * as marked from "marked";
@Pipe({
  name: 'marked'
})
export class MarkedPipe implements PipeTransform {
    renderer = new marked.Renderer();
  constructor() {
    //debugger;
    let linkRenderer = this.renderer.link;
    this.renderer.link = (href, title, text) => {
      let html = linkRenderer.call(this.renderer, href, title, text);
      //debugger;
      return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
    };
  }

  transform(value: any): any {
    if (value && value.length > 0) {
      marked.setOptions({
        renderer: this.renderer
      });
      return marked(value);
    }
    return value;
  }

}

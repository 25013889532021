import { EventEmitter, Injectable, Output } from '@angular/core';
import * as Msal from 'msal';
import { environment } from '../../environments/environment';
import { StoreTokenService } from './store-token-service.service';
import * as microsoftTeams from "@microsoft/teams-js";
import { AuthTokenService } from './AuthTokenService';
import { Observable, of, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthToken } from '../model/AuthToken';
 
import { HomeService } from './home.service';
import { JWTTokenService } from './JWTTokenService';
import { AuthenticationDataService } from './authentication-data.service';

@Injectable({
  providedIn: 'root'
})
export class UserLoginService {
  @Output() TokenChange: EventEmitter<AuthToken> = new EventEmitter();
  returnValue = "";
  subscription: Subscription;
  myAppUrl = '';
  tenantid = "";
  loginHint = "";
  constructor(private _http: HttpClient,
    private store: StoreTokenService,
    private token: AuthTokenService,
    private jwtToken: JWTTokenService,
    
    private authenticationdataservice: AuthenticationDataService
  ) {
    this.myAppUrl = environment.APIURL;
  }


  refreshToken() :Observable<AuthToken>
  {
        let tok = this.authenticationdataservice.get();
        let role:string = tok.role;
        role = role.replace("softel_","");
        let qrole = "?role=" + role;
        let qtoken = "&token=" + tok.adrefreshtoken;
  
    return this._http.get<AuthToken>(`${this.myAppUrl}api/RefreshToken${qrole}${qtoken}`);
  }
  // refreshToken() : Observable<AuthToken> {
  //   debugger;
  //   return new Observable<AuthToken>((req) => {
  //     let tok = this.authenticationdataservice.get();
  //       let role:string = tok.role;
  //       role = role.replace("softel_","");
  //       let qrole = "?role=" + role;
  //       let qtoken = "&token=" + tok.adrefreshtoken;
        
        
  //        this._http.get<AuthToken>(`${this.myAppUrl}api/RefreshToken${qrole}${qtoken}`)
  //        .subscribe((res)=> {req.next(res);});
  //   });
           
  // }


  // refreshToken() : Observable<AuthToken>  {
  //   debugger;
  //  // const resStatus = await this.h.getHomeDetails('pradeep@star-knowledge.org').toPromise();
  //   //this.h.getHomeDetails('pradeep@star-knowledge.org').subscribe(

  //   //  (data) => {
  //   //    debugger;
  //   //     console.log("home", data);
  //   //  }
  //   //)
  //   //this.h.getHomeDetails('pradeep@star-knowledge.org');
  //   // console.log("in once==================================================================");
  //   // Create form data 
  //   let tok = this.authenticationdataservice.get();
  //   let role:string = tok.role;
  //   role = role.replace("softel_","");
  //   let qrole = "?role=" + role;
  //   let qtoken = "&token=" + tok.adrefreshtoken;
  //   this._http.get
    
  //   return this._http.get<AuthToken>(`${this.myAppUrl}api/RefreshToken${qrole}${qtoken}`);
       
  //   // let headers = new HttpHeaders({
  //   //   'Content-Type': 'multipart/form-data',
  //   //   'Accept': 'application/json'
  //   // }
  //   // );

  //   // let options = { headers: headers };
  //   // const formData = new FormData();
  //   // const tokenData = { "refreshtoken": tok.adrefreshtoken, "role": role };
  //   // window.alert(token);
  //   //  console.log(tokenData);
  //   // window.alert("tokendata")
  //   //formData.append("value",answer);
  //   // Store form name as "file" with file data 
  //   //formData.append("model", angular.toJson(answer));
  //  // formData.append("Data", JSON.stringify(tokenData));
  //   //window.alert(this.myAppUrl + "api/TokenInterface");
  //   //let observable = this._http.post<AuthToken>(this.myAppUrl + "api/RefreshToken", formData);
  //   // observable.subscribe(
  //   //   (auth:AuthToken)=> {
  //   //     this.authenticationdataservice.set(auth);
  //   //     this.jwtToken.changeToken(auth);
  //   //   }
  //   // );
       
      
  //   // .pipe(tap
  //   //   ((tokens: AuthToken) => {
  //   //     this.authenticationdataservice.set(tokens);
  //   // }));
 
  // }

  getToken(token, tenantid) {
    debugger;
   // const resStatus = await this.h.getHomeDetails('pradeep@star-knowledge.org').toPromise();
    //this.h.getHomeDetails('pradeep@star-knowledge.org').subscribe(

    //  (data) => {
    //    debugger;
    //     console.log("home", data);
    //  }
    //)
    //this.h.getHomeDetails('pradeep@star-knowledge.org');
    // console.log("in once==================================================================");
    // Create form data 
    let headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json'
    }
    );

    let options = { headers: headers };
    const formData = new FormData();
    const tokenData = { "token": token, "tenantid": tenantid };
   // console.log(token);
    //console.log(tenantid);
   // window.alert(token);
    //  console.log(tokenData);
    // window.alert("tokendata")
    //formData.append("value",answer);
    // Store form name as "file" with file data 
    //formData.append("model", angular.toJson(answer));
    formData.append("Data", JSON.stringify(tokenData));
    //window.alert(this.myAppUrl + "api/TokenInterface");
  
    return this._http.post(this.myAppUrl + "api/TokenInterface", formData)
      .pipe(map(response => {
        //console.log("TokenInterface")
       // window.alert(response);
        return response
      }, error => {
        //window.alert("inside error");
        console.log(JSON.stringify(error));
      }
      ));
  }

  toQueryString(queryParams) {
    let encodedQueryParams = [];
    for (let key in queryParams) {
      encodedQueryParams.push(key + "=" + encodeURIComponent(queryParams[key]));
    }
    return encodedQueryParams.join("&");
  }
  _decimalToHex(number) {
    var hex = number.toString(16);
    while (hex.length < 2) {
      hex = '0' + hex;
    }
    return hex;
  }

  _guid() {
    // RFC4122: The version 4 UUID is meant for generating UUIDs from truly-random or
    // pseudo-random numbers.
    // The algorithm is as follows:
    //     Set the two most significant bits (bits 6 and 7) of the
    //        clock_seq_hi_and_reserved to zero and one, respectively.
    //     Set the four most significant bits (bits 12 through 15) of the
    //        time_hi_and_version field to the 4-bit version number from
    //        Section 4.1.3. Version4
    //     Set all the other bits to randomly (or pseudo-randomly) chosen
    //     values.
    // UUID                   = time-low "-" time-mid "-"time-high-and-version "-"clock-seq-reserved and low(2hexOctet)"-" node
    // time-low               = 4hexOctet
    // time-mid               = 2hexOctet
    // time-high-and-version  = 2hexOctet
    // clock-seq-and-reserved = hexOctet:
    // clock-seq-low          = hexOctet
    // node                   = 6hexOctet
    // Format: xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
    // y could be 1000, 1001, 1010, 1011 since most significant two bits needs to be 10
    // y values are 8, 9, A, B
    var cryptoObj = window.crypto; // for IE 11
    if (cryptoObj && cryptoObj.getRandomValues) {
      var buffer = new Uint8Array(16);
      cryptoObj.getRandomValues(buffer);
      //buffer[6] and buffer[7] represents the time_hi_and_version field. We will set the four most significant bits (4 through 7) of buffer[6] to represent decimal number 4 (UUID version number).
      buffer[6] |= 0x40; //buffer[6] | 01000000 will set the 6 bit to 1.
      buffer[6] &= 0x4f; //buffer[6] & 01001111 will set the 4, 5, and 7 bit to 0 such that bits 4-7 == 0100 = "4".
      //buffer[8] represents the clock_seq_hi_and_reserved field. We will set the two most significant bits (6 and 7) of the clock_seq_hi_and_reserved to zero and one, respectively.
      buffer[8] |= 0x80; //buffer[8] | 10000000 will set the 7 bit to 1.
      buffer[8] &= 0xbf; //buffer[8] & 10111111 will set the 6 bit to 0.
      return this._decimalToHex(buffer[0]) + this._decimalToHex(buffer[1]) +
        this._decimalToHex(buffer[2]) + this._decimalToHex(buffer[3]) + '-' +
        this._decimalToHex(buffer[4]) + this._decimalToHex(buffer[5]) + '-' +
        this._decimalToHex(buffer[6]) + this._decimalToHex(buffer[7]) + '-' +
        this._decimalToHex(buffer[8]) + this._decimalToHex(buffer[9]) + '-' +
        this._decimalToHex(buffer[10]) + this._decimalToHex(buffer[11]) +
        this._decimalToHex(buffer[12]) + this._decimalToHex(buffer[13]) +
        this._decimalToHex(buffer[14]) + this._decimalToHex(buffer[15]);
    }
    else {
      var guidHolder = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
      var hex = '0123456789abcdef';
      var r = 0;
      var guidResponse = "";
      for (var i = 0; i < 36; i++) {
        if (guidHolder[i] !== '-' && guidHolder[i] !== '4') {
          // each x and y needs to be random
          r = Math.random() * 16 | 0;
        }
        if (guidHolder[i] === 'x') {
          guidResponse += hex[r];
        } else if (guidHolder[i] === 'y') {
          // clock-seq-and-reserved first hex is filtered and remaining hex values are random
          r &= 0x3; // bit and with 0011 to set pos 2 to zero ?0??
          r |= 0x8; // set pos 3 to 1 as 1???
          guidResponse += hex[r];
        } else {
          guidResponse += guidHolder[i];
        }
      }
      return guidResponse;
    }
  }
  async Login() {
    const self = this;
   // window.alert("inside");
    debugger;
    microsoftTeams.initialize(() => {
      microsoftTeams.getContext(function (context) {
        //   console.log("================================Get context Values================================");
        //  console.log(JSON.stringify(context));
        self.tenantid = context.tid;
        self.loginHint = context.loginHint;
        self.store.set("hint", context.loginHint);
        self.store.set("tid", context.tid);
        //  window.alert(self.tenantid);
        //  console.log("================================End context Values================================");
      });


      microsoftTeams.authentication.getAuthToken(
        {
          successCallback: (result) => {
            // console.log("success");
            // console.log(result);
            debugger;
           // window.alert("gettoken");
            let toke = this.getToken(result, this.tenantid).subscribe((data: AuthToken) => {
              res => {
                //console.log("result=============================================");
                // window.alert("token rcvd");
              //  console.log(res);
                self.store.set('auth', data.access_token);
               // self.token.changeMessage(data.access_token);
               self.authenticationdataservice.set(data);
                self.jwtToken.changeToken(data);
               }
               err => {
                 console.log("error=============================");
                 console.log(JSON.stringify(err));
                 this.loginRetry();
               }
              //  window.alert("before");
              self.store.set('auth', data.access_token);
              //self.token.changeMessage(data.access_token);
              self.jwtToken.changeToken(data);
            });
          },
          failureCallback: (error) => {
            console.log("Error");
            // window.alert("Error while retrieving Authtoken")
            //window.alert("inside failurecallback");
            console.log(JSON.stringify(error));
          }

        });
    });
  }

  getHashParameters() {
    //let hashParams = {};
    let hashParams: { [k: string]: any } = {};
    location.hash.substr(1).split("&").forEach(function (item) {
      let s = item.split("="),
        k = s[0],
        v = s[1] && decodeURIComponent(s[1]);
      hashParams[k] = v;
    });
    return hashParams;
  }

  getTokenFromLogin() {
    //console.log("in token retrieve");
    let self = this;
    microsoftTeams.initialize(() => {
      localStorage.removeItem("auth.error");
      let hashParams = this.getHashParameters();
      if (hashParams["error"]) {
        // Authentication/authorization failed
        //  window.alert("error");
        //window.alert(JSON.stringify(hashParams));
        self.store.set("auth.error", JSON.stringify(hashParams));
        microsoftTeams.authentication.notifyFailure(JSON.stringify(hashParams));
      } else if (hashParams["access_token"]) {
        // Get the stored state parameter and compare with incoming state
        let expectedState = this.store.get("auth.state");
        if (expectedState !== hashParams["state"]) {
          // State does not match, report error
          self.store.set("auth.error", JSON.stringify(hashParams));
          microsoftTeams.authentication.notifyFailure("StateDoesNotMatch");
        } else {
          // Success -- return token information to the parent page.
          // Use localStorage to avoid passing the token via notifySuccess; instead we send the item key.
          let key = "auth.result";
          // TODO: not sure why this isn't being set
          self.store.set(key, JSON.stringify({
            idToken: hashParams["id_token"],
            accessToken: hashParams["access_token"],
            tokenType: hashParams["token_type"],
            expiresIn: hashParams["expires_in"]
          }));
          self.store.set('auth', hashParams["access_token"]);
          self.token.changeMessage(hashParams["access_token"]);
          microsoftTeams.authentication.notifySuccess(hashParams["access_token"]);

        }
      } else {
        // Unexpected condition: hash does not contain error or access_token parameter
        self.store.set("auth.error", JSON.stringify(hashParams));
        microsoftTeams.authentication.notifyFailure("UnexpectedFailure");
      }

    });
  }

  tryFreshLogin() {
    //window.alert("fresh");
    let state = this._guid();
    this.store.set("auth.state", state);
    this.store.remove("auth.error");
    this.tenantid = this.store.get("tid");
    this.loginHint = this.store.get("hint");
    //this.store.set("hint", this.loginHint);
    //this.store.set("tid", environment.TenantID);
    // See https://docs.microsoft.com/en-us/azure/active-directory/develop/active-directory-v2-protocols-implicit
    // for documentation on these query parameters
    let queryParams = {
      client_id: environment.ClientID,
      response_type: "id_token token",
      response_mode: "fragment",
      scope: "User.Read email openid profile offline_access",
      redirect_uri: window.location.origin + "/auth-end",
      nonce: this._guid(),
      state: state,
      login_hint: this.loginHint,
    };
    // Go to the AzureAD authorization endpoint (tenant-specific endpoint, not "common")
    // For guest users, we want an access token for the tenant we are currently in, not the home tenant of the guest. 
    let authorizeEndpoint = `https://login.microsoftonline.com/${this.tenantid}/oauth2/v2.0/authorize?${this.toQueryString(queryParams)}`;
    //window.alert(authorizeEndpoint);
    window.location.assign(authorizeEndpoint);
  }

  loginRetry() {


    ////to be removed after test
    //this.store.set("hint", this.loginHint);
    //this.store.set("tid", environment.TenantID);

    ////to be removed after test
    let self = this;
    microsoftTeams.initialize(() => {
      microsoftTeams.authentication.authenticate({
        url: window.location.origin + "/auth-start",
        width: 600,
        height: 535,
        successCallback: (result) => {
          self.store.set('auth', result);
          self.token.changeMessage(result);
        },
        failureCallback: (reason) => {
          self.store.remove("auth.start");
          // window.alert(JSON.stringify(reason));
          console.log(JSON.stringify(reason));
          //reject(JSON.stringify(reason));
        }
      });
    });
  }

  //async Login() {
  //   //debugger;
  //   let self = this;
  //   //this.store.remove('auth');
  //console.log("before init--------------------------------");
  //   microsoftTeams.initialize(() => {
  //     console.log("init");
  //     microsoftTeams.getContext(function (context) {
  //       //debugger;
  //       console.log("context");

  //       console.log(context.loginHint);
  //       //const myMSALObj = new this.msal this.authService.UserAgentApplication(config);

  //       console.log(context.teamSiteDomain);
  //       //console.log(context.loginHint);
  //       console.log("principal name");
  //       console.log(context.userPrincipalName);
  //       let accessTokenRequest = {
  //         scopes: ["user.read"],
  //         loginHint: context.userPrincipalName,
  //         //extraQueryParameters: { domain_hint: context.teamSiteDomain }
  //       }
  //       const config = {
  //         auth: {
  //           clientId: environment.ClientID,
  //           authority: environment.Authority + context.tid,


  //         }
  //       };
  //       const myMSALObj = new Msal.UserAgentApplication(config);
  //     //  myMSALObj.acquireTokenPopup

  //       myMSALObj.acquireTokenSilent(accessTokenRequest).then(
  //         function (accessTokenResponse: any) {
  //           // Acquire token silent success
  //           // call API with token
  //           //debugger;

  //           let accessToken = accessTokenResponse.accessToken;
  //           let scopes = accessTokenResponse.scopes;
  //           //self.dtoken= accessTokenResponse.accessToken;
  //           console.log(accessTokenResponse.accessToken);
  //           self.store.set('auth', accessTokenResponse.accessToken);
  //           self.returnValue = accessTokenResponse.accessToken;
  //           self.token.changeMessage(accessTokenResponse.accessToken);
  //         }).catch(function (error: any) {
  //           //Acquire token silent failure, and send an interactive request
  //           //debugger;
  //           console.log("inside silent auth error");
  //           console.log(error);
  //           console.log("=======error printed inside silent auth error==========================");

  //           //myMSALObj.acquireTokenPopup()
  //           //myMSALObj.acquireTokenPopup()
  //           let accessTokenRequestR = {
  //             scopes: ["user.read"],
  //             loginHint: context.userPrincipalName,
  //              redirectUri: environment.RedirectURL,
  //             //extraQueryParameters: { domain_hint: context.teamSiteDomain }
  //           }
  //           let tempara = {
  //             silent:true
  //           }
  //           //microsoftTeams.authentication.getAuthToken(tempara);
  //           //myMSALObj.acquireTokenPopup(accessTokenRequest);
  //           myMSALObj.acquireTokenPopup(accessTokenRequestR)
  //             .then(tokenResponse => {
  //               console.log("second attempt success");
  //               console.log(tokenResponse.accessToken);
  //               self.store.set('auth', tokenResponse.accessToken);
  //               self.token.changeMessage(tokenResponse.accessToken);
  //             }).catch(error => {
  //               console.log("second attempt failed");
  //               console.log(error);
  //             });
  //             //myMSALObj.acquireTokenRedirect(accessTokenRequest);
  //           // if (error.errorMessage.indexOf("interaction_required") !== -1) {
  //           //  myMSALObj.acquireTokenRedirect(accessTokenRequest);
  //           //}
  //         });
  //     });
  //   });
  //       return this.returnValue;
  // }
}


import { Component, OnInit } from '@angular/core';
import * as microsoftTeams from "@microsoft/teams-js";
import { StoreTokenService } from '../service/store-token-service.service';
import { UserLoginService } from '../service/user-login.service';

@Component({
  selector: 'app-auth-end',
  templateUrl: './auth-end.component.html',
  styleUrls: ['./auth-end.component.scss']
})
export class AuthEndComponent implements OnInit {

  constructor( private userservice: UserLoginService) { }

  ngOnInit(): void {
    this.userservice.getTokenFromLogin();
    //this.RedirectAfterLogin();
  }

  
}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Home } from '../model/Home';
import { HomeService } from '../service/home.service';
import { UserDetailsService } from '../service/user-details.service';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StoreTokenService } from '../service/store-token-service.service';
import { JwtCheckService } from '../service/jwt-check.service';
import { JWTTokenService } from '../service/JWTTokenService';
import { TenantDetailsService } from '../service/TenantDetails';
import { TenantDetails } from '../model/TenantDetails';
import { AuthenticationDataService } from '../service/authentication-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  userName: string;
  homeDetails: Home = new Home();
  // subscription: Subscription;
  tokenSubscription: Subscription;
  tenantImage: TenantDetails;
  insideSubs: boolean = false;
  tokenValue: string = "";
  constructor(private http: HttpClient,
    private homeService: HomeService,
    private userDetailService: UserDetailsService,
    private store: StoreTokenService, private jwtcheck: JwtCheckService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private authenticationdataservice: AuthenticationDataService,
    private jwtToken: JWTTokenService, private tenantDtlsSerivce: TenantDetailsService
  ) { }

 ngOnInit(){
   // window.alert("home");
  // window.alert(this.router.url);
   this.tokenSubscription = this.jwtToken.currentToken.subscribe(
     token => {
       this.insideSubs = true;
    //  window.alert("xxxxxsubsxxxxxx---home");
       if (token != null) {
         this.tokenValue = token.access_token;
       // window.alert("---token subs--");
        //debugger;
        this.authenticationdataservice.set(token);
        this.jwtcheck.setToken(token.access_token);
       // const response = this.userDetailService.validateUser(this.jwtcheck.getEmailId());
        this.userName = this.jwtcheck.getUser();
        //console.log(this.userName);
        // console.log(message);
        let email = this.jwtcheck.getEmailId();
        //debugger;
         this.spinner.show();
        this.homeService.getHomeDetails(email).subscribe(

          (data: Home) => {
            ////debugger
           // window.alert(JSON.stringify(data));
            this.homeDetails = data;
            this.tenantDtlsSerivce.set(data.tenantDetails);
            this.tenantImage = data.tenantDetails;
            this.spinner.hide();
          }
         )
         
       }
       else {
         this.spinner.hide();
        this.router.navigate(['/login']);
       }
     },
     error => { console.log(error) });
   let token = this.authenticationdataservice.get();
   //check for the latest token
   if (token?.access_token != undefined)
     if (this.tokenValue != token.access_token)
       this.insideSubs = false;

   if (this.insideSubs == false &&
     token.accessToken != undefined)
   {
     this.spinner.show();
     this.jwtcheck.setToken(token.access_token);
     // const response = this.userDetailService.validateUser(this.jwtcheck.getEmailId());
     this.userName = this.jwtcheck.getUser();
     //console.log(this.userName);
     // console.log(message);
     let email = this.jwtcheck.getEmailId();
     //debugger;
     this.spinner.show();
     this.homeService.getHomeDetails(email).subscribe(

       (data: Home) => {
    
         ////debugger
         // window.alert(JSON.stringify(data));
         this.homeDetails = data;
         this.tenantDtlsSerivce.set(data.tenantDetails);
         this.tenantImage = data.tenantDetails;
         this.spinner.hide();
       }
     )
     
   }
    //if(!response.isMigrationUser){
    // if(!response.isMigrationUser){

    //   this.router.navigate(['/autherror']);
    // }
    //this.router.navigate(['/askforhelp'])
    //const res = await this.userDetailService.getProfile();
    //this.userName=res.displayName

    //res.mail


  }
  ngOnDestroy() {
    this.tokenSubscription.unsubscribe();
  }
}


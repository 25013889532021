import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';
import { Home } from '../model/Home';
import { ScreenAccess } from '../model/ScreenAccess';
import { HomeService } from './home.service';
import { HttperrordisplayService } from './httperrordisplay.service';
import { JwtCheckService } from './jwt-check.service';
import { StoreTokenService } from './store-token-service.service';

 
@Injectable({
  providedIn: 'root'
})
export class TicketGuardService implements CanActivate {

  constructor(private jwtService: JwtCheckService,
    private authStorageService: StoreTokenService,
    private homeService: HomeService,
    private router: Router, private errorDisplay: HttperrordisplayService) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    //debugger;
    this.jwtService.setToken(this.authStorageService.get("auth"));
    let cnt = 0;
    // console.log("before while");
    if (!this.jwtService.getUser()) {
      this.router.navigate(['/autherror']);
      return false;
    }
    const email = this.jwtService.getEmailId();
    const resStatus = await this.homeService.getTicketAccess(email).toPromise();
    let denyMessage = await this.homeService.getTicketDeniedMessage(email).toPromise() as any;
    let isTicket = resStatus as ScreenAccess;

    //debugger

    if (isTicket.isTicketOpen==false) {
      this.errorDisplay.showError(denyMessage.message);
      return false
    }
    else {
      return true;
    }
  }
}

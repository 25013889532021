import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  myAppUrl = '';
  constructor(private _http: HttpClient) { 
    this.myAppUrl = environment.APIURL;
  }
  getHomeDetails(username:string){
   // console.log(this.myAppUrl + "api/Dashboard/" + username);
    // debugger;
    return this._http.get(this.myAppUrl+"api/Dashboard/" + username).pipe(map(response=>{
      return response;
    }));
  }

  getTicketDeniedMessage(username: string) {
    
    return this._http.get(this.myAppUrl + "api/TicketDeniedMessage/" + username).pipe(map(response => {
      return response;
    }));
  }

  getTicketAccess(username:string){
    // console.log(this.myAppUrl + "api/Dashboard/" + username);
     // debugger;
     return this._http.get(this.myAppUrl+"api/TicketAccess/" + username).pipe(map(response=>{
       return response;
     }));
   }
}

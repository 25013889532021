import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';
import { JwtCheckService } from './jwt-check.service';
import { UserDetailsService } from './user-details.service';
import { StoreTokenService } from './store-token-service.service';
import { UserLoginService } from './user-login.service';
import { Subscription } from 'rxjs';
import { JWTTokenService } from './JWTTokenService';
import { AuthenticationDataService } from './authentication-data.service';
@Injectable({
  providedIn: 'root'
})
export class CustomAuthGuardService implements CanActivate {

  constructor(private jwtService: JwtCheckService,
    private authStorageService: StoreTokenService,
    private router: Router,
    private userService: UserDetailsService,
    private authenticationdataservice: AuthenticationDataService,
    private auth: UserLoginService, private jwtToken: JWTTokenService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let tokenSubscription: Subscription;
    let tick: string = "";
    let adtick: string = "";
    // debugger;
    // window.alert("can");
    tokenSubscription = this.jwtToken.currentToken.subscribe(token => {
      if (token != null) {
        tick = token.accessToken;
        adtick = token.access_token;

      }
    });
    if (adtick == '') {
      if (this.authenticationdataservice?.get().access_token != undefined) {
        adtick = this.authenticationdataservice?.get().access_token;
      }
    }
    if (adtick == '') {
      this.router.navigate(['/login']);
      return false;
    }
    //debugger;
    this.jwtService.setToken(adtick);
    let cnt = 0;
    // console.log("before while");
    /*while (this.jwtService.getUser()) {
      if (this.jwtService.isTokenExpired()) {
        this.auth.Login();
        this.jwtService.setToken(adtick);
      }
      else {
        //console.log("break");
        break;
      }
      cnt = cnt + 1;
      if (cnt == 2) {
        //console.log("error ");
        this.router.navigate(['/autherror']);
        return false;
      }
    }
    */
    if (!this.jwtService.getUser()) {
      this.router.navigate(['/autherror']);
      return false;
    }
    const email = this.jwtService.getEmailId();
    const res = await this.userService.validateUser(email);
    //debugger
    if (res.isMigrationUser) {
      return true;
    } else {
      this.router.navigate(['/autherror']);
      return false;
    }
  }
}

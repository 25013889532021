import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class DataService {

  private messageSource = new BehaviorSubject('');
  private messagePriority = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();
  currentPriority = this.messagePriority.asObservable();
  constructor() { }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  changePriority(priority: string) {
    this.messagePriority.next(priority);
  }



}


import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonalMessageService {
  myAppUrl = '';
  constructor(private _http: HttpClient) {
    this.myAppUrl = environment.APIURL;  
   }

  getMessages(username: string) {
    return this._http.get(this.myAppUrl+"api/PM/"+username)
    .pipe(map(response=>{
      //debugger
      return response;
    }));
  }
}

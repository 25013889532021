import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreTokenService {

  constructor() { }

  set(key: string, value: string) {
    localStorage.setItem(key, value);
}

get(key: string):string {
    let authtoken=localStorage.getItem(key)==null?"":localStorage.getItem(key);
    return authtoken==null?"":authtoken;
}

remove(key: string) {
    localStorage.removeItem(key);
}

}

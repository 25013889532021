import { Injectable } from '@angular/core';
 
import { TenantDetails } from '../model/TenantDetails';
 
 

@Injectable({
  providedIn: 'root'
})
export class TenantDetailsService {
  private tenantDetails: TenantDetails = null;

  constructor() { }

  get(): TenantDetails {
    return this.tenantDetails
  }
  set(newvalue: TenantDetails) {
    this.tenantDetails = newvalue;
  }

}

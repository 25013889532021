import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthToken } from '../model/AuthToken';
import { AuthenticationDataService } from './authentication-data.service';

@Injectable({
  providedIn: 'root'
})
export class JWTTokenService {
  auth: AuthToken = null;
  private tokenSource = new BehaviorSubject(this.auth);

  currentToken = this.tokenSource.asObservable();

  constructor(private authenticationdataservice: AuthenticationDataService) { }

  changeToken(newtoken: AuthToken) {
    this.auth = newtoken;
    //this.authData.set(newtoken);
    this.authenticationdataservice.set(newtoken);
    this.tokenSource.next(newtoken);
  }


}

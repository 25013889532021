import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { AuthenticationDataService } from '../../../../service/authentication-data.service';
import { JWTTokenService } from '../../../../service/JWTTokenService';
import { LocalstoreeventsService } from '../../../../service/localstoreevents.service';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationHandlerService {
  private loginDisplay = false;
  private role: string;
  constructor(
    private jwtToken: JWTTokenService,
    private _http: ApiService,
    private authdataservice: AuthenticationDataService,
    private tabEvent:LocalstoreeventsService

    ) { }
  
  getToken(token, tenantid):string {
    //console.log("in once==================================================================");
    // Create form data
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    }
    );

    const options = { headers: headers };
    const formData = new FormData();
    const tokenData = { 'token': token, 'tenantid': tenantid };
    //console.log(token);
    // window.alert('tokendata')
    // formData.append('value',answer);
    // Store form name as 'file' with file data 
    // formData.append('model', angular.toJson(answer));
    formData.append('Data', JSON.stringify(tokenData));
    // window.alert(this.myAppUrl + 'api/TokenInterface');
    let api_data = '';
    this._http.post('TokenInterfaceAdmin', formData).subscribe((res: any) => {
     // console.log('<---------------tenant details token-------------->');
     // console.log('<----res--->', res);
      //console.log('<---------------done tenant details token-------------->');

      if (res?.status === 500) {
        // this.router.navigate(['admin/not-found']);
      } else {
        //let aam = new AdminAuthModel();
        //aam.AuthToken = res.access_token;
        //aam.OToken = res.accessToken;
        //aam.UserId = res.role;
        //this.adminauthdataservice.set(aam);
        this.tabEvent.store("temp",res);
        this.authdataservice.set(res);
        this.jwtToken.changeToken(res);
       // environment.Guest = res?.role === 'softel_HelpDesk' ? true : false;
       // this.storeToken.set('auth', JSON.stringify(res));

        //this.getTenantList();
        //console.log(this.totalRecords, 'this.totalRecords');
        //api_data = res;
        return res?.role;
      }
    }, err => {
      // window.alert('inside error');
      console.log(JSON.stringify(err));
    });
    return "";
    // .pipe(map(response => {

    // window.alert(response);
    //   return response
    // }, error => {
    // window.alert("inside error");
    //   console.log(JSON.stringify(error));
    // }
    // ));
  }

  checkAndSetActiveAccount(authService: MsalService,) {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = authService.instance.getActiveAccount();

    if (!activeAccount && authService.instance.getAllAccounts().length > 0) {
      let accounts = authService.instance.getAllAccounts();
      //console.log('accounts---', accounts);
      authService.instance.setActiveAccount(accounts[0]);
    }
  }
  async checkx():Promise<string>{
    // const activeAccount = this.authServiceN.instance.getActiveAccount();
    //   console.log('activeAccount---', activeAccount);
    //   const accessTokenRequest = {
    //     scopes: [environment.Scope],
    //     account: activeAccount
    //   };
    //   this.authServiceN.acquireTokenSilent(accessTokenRequest).subscribe(
    //     (tok: AuthenticationResult) => {
    //       console.log('silent authenticaton handler===============================================================');
    //       //console.log(tok);
    //       // this.adata.APIToken = "2";
    //       this.role = this.getToken(tok.idToken, tok.tenantId);
    //       return this.role;
    //       //console.log('getToken---', getToken);
    //     });
    return "";
  }
 async check(authService: MsalService): Promise<string> {
    
      const activeAccount = authService.instance.getActiveAccount();
      //console.log('activeAccount---', activeAccount);
      const accessTokenRequest = {
        scopes: [environment.Scope],
        account: activeAccount
      };
      authService.acquireTokenSilent(accessTokenRequest).subscribe(
        (tok: AuthenticationResult) => {
        //  console.log('silent authenticaton handler===============================================================');
          //console.log(tok);
          // this.adata.APIToken = "2";
          this.role = this.getToken(tok.idToken, tok.tenantId);
          return this.role;
          //console.log('getToken---', getToken);
        });
    return "";
    }
    //this.msalBroadcastService.msalSubject$
    //  .pipe(
    //    filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    //  )
    //  .subscribe((result: EventMessage) => {
    //    // window.alert("test Tenant Details msal Broadcast service");
    //    //console.log("====================================================================================1");
    //    //console.log(result);
    //    // this.authService.acquireTokenSilent
    //    //console.log("====================================================================================2");
    //    // this.adata = new AppToken()
    //    // window.alert("cc");
    //    // window.alert(JSON.stringify(result));
    //    const payload = result.payload as AuthenticationResult;
    //    //this.authService.instance.setActiveAccount(payload.account);
    //    //this.adata.AccessToken = payload.accessToken;
    //    //this.adata.APIToken = "1";
    //    //debugger;
    //    //this.authdataservice.changeMessage(this.adata);
    //    let accessTokenRequest = {
    //      scopes: [environment.Scope],
    //      account: payload.account
    //    }
    //    this.authService.acquireTokenSilent(accessTokenRequest).subscribe(
    //      (tok: AuthenticationResult) => {
    //        console.log("silent Line 144=======================Auth Handler========================================");
    //        //console.log(tok);
    //        //this.adata.APIToken = "2";
    //      }
    //    );
    //  });
   
}

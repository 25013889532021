export class Ticket {
  name: string;
  email: string;
  phoneNumber: string;
  priority: string;
  subject: string;
  problemDescription: string;
}
export class TicketAnswer {
  subject: string;
  userName: string;
  requestDetails: string;
  displayName: string;
  companyName: string;
  city: string;
  phone: string;
  priority: string;
  creationdatetime: string;


}
export class TicketResponse {
  ticketNo: string;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';


const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  displayName?: string
  mail?: string
  mobilePhone?: string
}

type OtherDetails = {
  id?: string
  businessPhones?:string []
  displayName?: string
  companyName?: string
  streetAddress?: string
  city?: string
  state?: string
  country?: string
  postalCode?:string
}

type AuthDetails = {
  isMigrationUser?: boolean
  message?: string
}

type CustomerJourneyDetails = {
  UserName?: string
  JourneyPlannedStartDate?: Date
  JourneyActualStartDate?: Date
  Phase1PlannedStartDate?: Date
  Phase2PlannedStartDate?: Date
  Phase3PlannedStartDate?: Date
  Phase1EndDate?: Date
  Phase2PEndDate?: Date
  Phase3EndDate?: Date
  CustomerSiteId?: String
}
@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
  profile!: ProfileType;
  authDetails!: AuthDetails;
  otherdetails: OtherDetails;
  userjourneydetails: CustomerJourneyDetails;
  graphUri = environment.GraphURL;


  constructor(private http: HttpClient) { }
  
  async getProfile() {
      //  this.http.get(GRAPH_ENDPOINT)
      // .subscribe(profile => {
      //   this.profile = profile;
      // });
    //debugger;
    return this.http.get( this.graphUri )
      .pipe(map(Response => {
        //debugger
         this.profile = Response;
         return this.profile;
      })).toPromise();    
    }

  async getJourney(username: string) {
    return this.http.get(environment.APIURL + "api/JourneyUserDetails/" + username)
      .pipe(map(response => {
        this.userjourneydetails=response;
        return this.userjourneydetails;
      })).toPromise();
  }
  
    async validateUser(username:string){
      return this.http.get(environment.APIURL+"api/JourneyUser/"+username)
      .pipe(map(response=>{
        //debugger
        this.authDetails=response;
        return this.authDetails;
      })).toPromise();
    }

  async getOtherData() {
 
    return this.http.get(this.graphUri +
      '?$select = id, businessPhones, displayName, companyName, streetAddress, city, state, country, postalCode')
      .pipe(map(Response => {
        //debugger
        this.otherdetails = Response;
        return this.otherdetails;
      })).toPromise();
   }
}

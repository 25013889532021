/*
    Base Api Service
    Created : 06 April 2021 by Darshan
*/

// Some needed modules --------- trying removing one
import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpParams,
    HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from '../../../../environments/environment';

// Essential Variables and Annotations
@Injectable({
    providedIn: 'root',
})

// Export Modules
export class ApiService {
    prefix = 'api/';
    constructor(private http: HttpClient) { }

    // Error Handling
    private formatErrors(error: any) {
        //console.log(error);
        if (error.message) {
            return [{ status: error.status, message: error.message }];
        } else {
            return [{ status: error.status, message: 'I think you have been disconnected!' }];
        }
    }

    // GET Request Handler
    get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        // console.log(this.prefix)
        // console.log(path)
        return this.http.get(
            `${environment.APIURL}${this.prefix}${path}`, { params })
            .pipe(catchError(this.formatErrors));
    }

    public _httpOptions_Json = {
        headers: new HttpHeaders({
            Authorization: `Token${localStorage.getItem("access_token")}`,
            // 'Content-Type': 'multipart/form-data';
        })
    };

    // POST Request Handler
    post(path: string, body: any, options?): Observable<any> {
        const HttpUploadOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        };
        return this.http.post(
            `${environment.APIURL}${this.prefix}${path}`,
            body, options ? options : { headers: HttpUploadOptions },
        ).pipe(catchError(this.formatErrors));
    }


    postFile(path: string, body: Object = {}): Observable<any> {
        const HttpUploadOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data',
            }),
        };
        return this.http.post(
            `${environment.APIURL}${this.prefix}${path}`,
            body, HttpUploadOptions,
        ).pipe(catchError(this.formatErrors));
    }

    // POST Request Handler for printer
    // postPrinter(body: Object = {}): Observable<any> {
    //     const HttpUploadOptions = {
    //         headers: new HttpHeaders({
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //         }),
    //     };
    //     return this.http.post(
    //         `${environment.printer_url}`,
    //         body, HttpUploadOptions,
    //     ).pipe(catchError(this.formatErrors));
    // }

    // PUT Request Handler
    put(path: string, body: Object = {}, options?): Observable<any> {
        const HttpUploadOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        };

        return this.http.put(
            `${environment.APIURL}${this.prefix}${path}`,
            body, options ? options : { headers: HttpUploadOptions },
        ).pipe(catchError(this.formatErrors));
    }

    // DELETE Request Handler
    delete(path, params: HttpParams = new HttpParams()): Observable<any> {
        return this.http.delete(`${environment.APIURL}${this.prefix}${path}`, { params }).pipe(catchError(this.formatErrors));
    }

    deleteBatch(path, body:object={}): Observable<any> {
        return this.http.request('delete',`${environment.APIURL}${this.prefix}${path}`, {body:body}).pipe(catchError(this.formatErrors));
    }

}

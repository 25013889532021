import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, finalize, map, retry, switchMap, take, tap } from 'rxjs/operators';
import { HttperrordisplayService } from './service/httperrordisplay.service';
import { UserLoginService } from './service/user-login.service';
import { AuthenticationHandlerService } from './admin/common/services/Authentication/authentication-handler.service';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationDataService } from './service/authentication-data.service';
import { JwtCheckService } from './service/jwt-check.service';
import { JWTTokenService } from './service/JWTTokenService';
import { environment } from '../environments/environment';
import { AuthToken } from './model/AuthToken';
import { Router } from '@angular/router';
import { ErrorService } from './service/ErrorService';
import { LocalstoreeventsService } from './service/localstoreevents.service';
 
 
@Injectable()
export class AppHttpErrorInterceptor implements HttpInterceptor {

  private tick: string = "";
  private errmessage:string='<div class="row"><div class="col-lg-12 col-12"><h4 class="pt-3">You are already logged in  </h4>  </div></div><div class="row"><div class="col-lg-12 col-12"></div></div>';
  constructor(private errorDisplay: HttperrordisplayService,
    private userservice: UserLoginService,
    private authenticationhandlerservice: AuthenticationHandlerService,
    private injector:Injector,
    private jwtcheck: JwtCheckService,
    private userlogin: UserLoginService,
    private jwtToken: JWTTokenService,
    private router: Router,
    private authenticationdataservice: AuthenticationDataService,
    private _http: HttpClient,
    private tabEvent: LocalstoreeventsService,
    private errorService: ErrorService) {
  }
  private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private refreshTokenInProgress = false;
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let tokenSubscription: Subscription;
    let adtick: string = "";
    let role:string ="";
    let refreshToken:string="";
  /*  tokenSubscription = this.tabEvent.changes.subscribe(token =>
      {
        let au:any = token;
        this.authenticationdataservice.set(au);
      }
      );*/
   /* tokenSubscription = this.jwtToken.currentToken.subscribe(token => {
      // console.log('token--int------1---->', token);
       if (token != null) {
         this.tick = token.accessToken;
         adtick = token.access_token;
         refreshToken=token.adrefreshtoken;
         role = token.role;
       } 
       
     });*/
      debugger;
      //let tt = this.tabEvent.getStorage();
     if(httpRequest.url.indexOf('TokenInterfaceAdmin') !== -1 || 
    httpRequest.url.indexOf('TokenInterface') !== -1 || 
    httpRequest.url.indexOf('RefreshToken') !== -1 
     )
     {
      // debugger;
       return next.handle(httpRequest).pipe(

         catchError((error: HttpErrorResponse) => {
           // debugger;
          // console.log(error);
           
             //this.responseMessage = msg;
             // this.myModal.show();

         //  debugger;
           if (httpRequest.url.indexOf('TokenInterface')) {
             this.errorService.changeError(error.error.Message);
             this.router.navigate(['/autherror']);
           }
             return throwError(error);
        
         })
       );
     }

     let tok = this.authenticationdataservice.get();
     //console.log("<-------------------------------what is this ------------>", tok?.accessToken != undefined);
     if(tok?.accessToken != undefined)
     {
       this.tick = tok?.accessToken;
       adtick = tok?.access_token;
       refreshToken=tok.adrefreshtoken;
       role = tok.role;
     }
     this.jwtcheck.setToken(adtick);
     if (httpRequest.url.indexOf('graph') !== -1) {
      this.tick = adtick;
      //console.log('----------2---', tick);
    }
  //  debugger;
    
    httpRequest  = this.addAuthenticationToken(httpRequest);

    return next.handle(httpRequest)
      .pipe(
        
        catchError((error: HttpErrorResponse) => {
          // debugger;
          //console.log(error);
          if(error.status==401)
          {
            return this.handle401Error(httpRequest,next,error);
          }
          else
          {
            if (error.status == 403) {
              this.errorService.changeError(this.errmessage);
              this.router.navigate(['/autherror']);
            }
            //if (error.message="")
          this.errorDisplay.handleError(error);
     
          //this.responseMessage = msg;
          // this.myModal.show();
          return throwError(error);
          }
        })
      )

  }

 
  handle401Error(req: HttpRequest<any>, next: HttpHandler,error: HttpErrorResponse) {
    debugger;
    let isjourneyUser:boolean=true;
      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      let role= "";
      let authserv = this.authenticationdataservice.get();
      role=this.authenticationdataservice.get()?.role;

    if (this.refreshTokenInProgress) {
      // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
      // which means the new token is ready and we can retry the request again
      return this.tokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(() => 
        next.handle(this.addAuthenticationToken(req))
        
        )
      );

    }
    else {
     // debugger;
      this.refreshTokenInProgress = true;
      this.tokenSubject.next(null);
      //this.Refresh().subscribe();
      let newTok:AuthToken ;
      return this.userservice.refreshToken().pipe(
        switchMap((res) => {
         this.tick=res.accessToken;
          this.tokenSubject.next(res.accessToken);
          //this.jwtToken.changeToken(res);
         // repeat failed request with new token
         this.tabEvent.store("temp",res);
         
          this.authenticationdataservice.set(res);
          this.refreshTokenInProgress = false;
         return next.handle(this.addAuthenticationToken(req));
        })
       );
      // this.userservice.refreshToken().subscribe(
      //   (success:AuthToken)=>{
      //     //newTok=success;
      //    // this.jwtToken.changeToken(success);
      //     this.authenticationdataservice.set(success);
      //     location.reload();
      //     //of(1).pipe(switchMap((success) => {
      //     // this.tokenSubject.next("success");
      //     // this.refreshTokenInProgress = false
      //     // return next.handle(this.addAuthenticationToken(req));
      //  // }))
      // });
        
        
        // When the call to refreshToken completes we reset the refreshTokenInProgress to false
        // for the next time the token needs to be refreshed
        
      
      
      // return  this.refreshToken().pipe( 
      //  switchMap((auth: AuthToken) => {
      //    // this.authenticationdataservice.set(success);
      //     //this.authenticationdataservice.set(auth);
      //     this.tokenSubject.next("success");
      //     this.refreshTokenInProgress = false;
      //     let x  = auth.accessToken;
      //     return next.handle(this.addAuthenticationToken(req));
      //   }),
      //   // When the call to refreshToken completes we reset the refreshTokenInProgress to false
      //   // for the next time the token needs to be refreshed
      //   finalize(() => (this.refreshTokenInProgress = false))
      // );
    }
  }
  private   Refresh(): Observable<AuthToken>
  {
    //debugger;
    return new Observable<AuthToken>((req) => {
      this.userservice.refreshToken().subscribe((res)=> {req.next(res);});
    });

     
  }
  
//   private refreshToken():Observable<AuthToken>{
//     return this.userservice.refreshToken().pipe(
//         map((authRespose):AuthToken => authRespose),
//         tap((token:AuthToken) => {
//         console.log("Here I am"); 
//         })
//     );
// }
  // private CallRefresh()
  // {
 
  //         // return this.userservice.refreshToken(tok.adrefreshtoken,role).pipe
  //         // (   switchMap((success: boolean) => {
  //         // }
  //         let tok = this.authenticationdataservice.get();
  //         let role:string = tok.role;
  //         role = role.replace("softel_","");
  //         let currentToken:AuthToken= new AuthToken();
  //     let headers = new HttpHeaders({
  //                 'Content-Type': 'multipart/form-data',
  //                 'Accept': 'application/json'
  //               }
  //               );
  //               let options = { headers: headers };
  //              const formData = new FormData();
  //              const tokenData = { "refreshtoken": tok.adrefreshtoken, "role": role };
  //              debugger;
  //              formData.append("Data", JSON.stringify(tokenData));
  //      return this._http.post(environment.APIURL + "api/RefreshToken", formData);
     
  // }
  //        private   refreshAccessToken():  Observable<boolean> {
  //         let op = this.CallRefresh();

  //         return of(true);
  //       }

      
        private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
          //let authserv = this.authenticationdataservice.get();
          
          return request.clone({
            headers: request.headers.set("Authorization",`Bearer ${this.tick}`)
          });
        }



}


import { Component, OnInit } from '@angular/core';
import { PersonalMessage } from '../model/PersonalMessage';
import { TenantDetails } from '../model/TenantDetails';
import { PersonalMessageService } from '../service/personal-message.service';
import { TenantDetailsService } from '../service/TenantDetails';
import { UserDetailsService } from '../service/user-details.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-personal-message',
  templateUrl: './personal-message.component.html',
  styleUrls: ['./personal-message.component.scss']
})
export class PersonalMessageComponent implements OnInit {
  messages: PersonalMessage[];
  isTable: string = 'true';
  messageDetails: string;
  isMessage: Boolean = false;
  tenantImage: TenantDetails;
  constructor(private personalMsgService: PersonalMessageService,
    private userDetailsService: UserDetailsService,
    private spinner: NgxSpinnerService,
    private tenantDtlsSerivce: TenantDetailsService) { }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    const res = await this.userDetailsService.getProfile();
    //res.mail
    ////debugger;
    this.personalMsgService.getMessages(res.mail).toPromise()
      .then(data => {
        this.messages = data as PersonalMessage[];
        debugger;
        this.isMessage = this.messages?.length == 0
        this.spinner.hide();
        //console.log(JSON.stringify(this.messages));
      }

    );

    this.tenantImage = this.tenantDtlsSerivce.get();
  }

  details(message: string) {
    this.isTable = "false";
    this.messageDetails = message;
  }
  back() {
    this.isTable = "true";
  }
}


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AskForHelpService {
  myAppUrl = '';
  constructor(private _http:HttpClient) { 
    this.myAppUrl = environment.APIURL;
  }

  //getAnswer(question: string) {
  //  //debugger;
  //  return this._http.get(this.myAppUrl + "api/Help/" + encodeURIComponent( question)).pipe(map(res=>{return res}))   
  //}
  getAnswer(question: string) {
    //debugger;
    let edata = { "question": question };
    return this._http.post(this.myAppUrl + "api/Help", edata).pipe(
      map(
        res => { return res }
      )
    )
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class ErrorService {

  private errorSource = new BehaviorSubject('');
  currentError = this.errorSource.asObservable();
  
  constructor() { }

  changeError(error: string) {
    this.errorSource.next(error)
  }

}

import { RefreshToken } from "./RefreshToken";

export class AuthToken {

  token_type: string;
  scope: string;
  expires_in: string;
  ext_expires_in: string;
  access_token: string;
  accessToken: string;
  role: string;
  refreshToken: RefreshToken;
  adrefreshtoken: string;
}

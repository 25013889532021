import { Component, OnInit } from '@angular/core';
import { UserLoginService } from '../service/user-login.service';
@Component({
  selector: 'app-auth-start',
  templateUrl: './auth-start.component.html',
  styleUrls: ['./auth-start.component.scss']
})
export class AuthStartComponent implements OnInit {

  constructor(private userservice: UserLoginService) { }

  ngOnInit(): void {
 
    this.userservice.tryFreshLogin();
    
  }


}

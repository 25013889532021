import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { TicketAnswer, TicketResponse } from '../model/Ticket';
import { DataService } from '../service/DataService';
import { TicketService } from '../service/ticket.service';
import { UserDetailsService } from '../service/user-details.service';
import { DatePipe } from '@angular/common';
import { TenantDetailsService } from '../service/TenantDetails';
import { TenantDetails } from '../model/TenantDetails';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {
  @ViewChild('myModal') public myModal: ModalDirective;
  ticketForm: FormGroup;
  answer: TicketAnswer = new TicketAnswer();
  file: File = null;
  responseMessage: string;
  otherdetails: any;
  journeydetails: any;
  udesc: string;
  usubject: string;
  subscription: Subscription;
  tenantImage: TenantDetails;
  saveinprogress:boolean=false;
  constructor(private router: Router,
    private _fb: FormBuilder,
    private userDetails: UserDetailsService,
    private tickteService: TicketService,
    private data: DataService, private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private tenantDtlsSerivce: TenantDetailsService
  ) {

    this.ticketForm = this._fb.group({
      priority: ['Standard', [Validators.required]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      problemDescription: ['', [Validators.required]],
      location: ['', [Validators.required]],
      agency: ['', [Validators.required]],
    })

  }

  async ngOnInit(): Promise<void> {
    //debugger;
   
    const res = await this.userDetails.getProfile();
    this.otherdetails = await this.userDetails.getOtherData();
   
    //debugger;
    this.journeydetails = await this.userDetails.getJourney(res.mail);
    //debugger;
    this.ticketForm.controls.email.setValue(res.mail);
    this.ticketForm.controls.name.setValue(res.displayName);
    this.ticketForm.controls.phoneNumber.setValue(this.otherdetails.businessPhones[0] == null ?
      "" : this.otherdetails.businessPhones[0]);
    this.ticketForm.controls.location.setValue(this.journeydetails.customerSiteId == null ?
      "" : this.journeydetails.customerSiteId);
    this.ticketForm.controls.agency.setValue(this.otherdetails.companyName == null ?
      "" : this.otherdetails.companyName);
    this.subscription = this.data.currentPriority.subscribe(message => {
      this.ticketForm.controls.priority.setValue(message);
      if (this.ticketForm.controls.priority.value == "")
        this.ticketForm.controls.priority.setValue("Standard");
    });
    this.tenantImage = this.tenantDtlsSerivce.get();

  }
  save() {
    // if (!this.ticketForm.valid) {
    //   return;
    // }
    //debugger
    // this.myModal.show();
    let config = {
      // animated: true,
      keyboard: false,
      backdrop: false,
      ignoreBackdropClick: true
    };
    this.saveinprogress=true;
    let currentDatetime = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
   // console.log("date");
    //console.log(currentDatetime);
    //console.log("end date");

    this.answer.userName = this.ticketForm.controls.email.value;
    this.answer.subject = this.ticketForm.controls.subject.value;
    this.answer.requestDetails = this.ticketForm.controls.problemDescription.value;
    this.answer.city = this.journeydetails.customerSiteId == null ?
      "" : this.journeydetails.customerSiteId;
    this.answer.companyName = this.otherdetails.companyName == null ? "" : this.otherdetails.companyName;
    this.answer.displayName = this.otherdetails.displayName == null ? "" : this.otherdetails.displayName;
    this.answer.phone = this.ticketForm.controls.phoneNumber.value;
      //this.otherdetails.businessPhones[0] == null ? "" : this.otherdetails.businessPhones[0];
    this.answer.priority = this.ticketForm.controls.priority.value;
    this.answer.creationdatetime = currentDatetime;
    this.responseMessage = "";
    this.spinner.show();
    this.tickteService.save(this.answer, this.file).subscribe((data: TicketResponse) => {
      //debugger;
      res => {
      this.responseMessage = "Successfully submitted ticket " + data.ticketNo;
      if (data.ticketNo == null || data.ticketNo == '' )
        this.responseMessage = "Error ";
        this.myModal.show();
        this.spinner.hide();
        return;
      }
      err => {
        //this.responseMessage = "Error ";
        //this.myModal.show();
        console.log(err.message);
        this.spinner.hide();
        return;
      }
      this.responseMessage = "Successfully submitted ticket " + data.ticketNo;
      if (data.ticketNo == null || data.ticketNo == '')
        this.responseMessage = "Error ";
      //this.responseMessage = "Error ";
      //this.myModal.onHide
      this.myModal.config = config;
      this.spinner.hide();
      this.myModal.show();
      //this.ticketForm.reset();
      this.ticketForm.controls.problemDescription.setValue("");
      this.ticketForm.controls.subject.setValue("");
      this.saveinprogress = false;
    });
    //console.log(this.ticketForm.value.email);

  }
  onChange(event) {
    this.file = event.target.files[0];
  }
  back() {
    //debugger;
    if (this.responseMessage != "Error")
      this.router.navigate(['/home']);
    else
      this.myModal.hide();
  }
  ngOnDestroy() {
    this.data.changePriority("");
    this.subscription.unsubscribe();
  }

  // helpPopup(){
  //  //debugger
  //   // this.ticketForm.controls.subject.setValue('');
  //   // this.ticketForm.controls.problemDescription.setValue('');
  //   this.router.navigate(['/home']);

  // }
}


import { Component, OnInit } from '@angular/core';
import { AskForHelpService } from '../service/ask-for-help.service';
import { Subscription } from 'rxjs';
import { DataService } from '../service/DataService';
//import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { Router } from '@angular/router';
import { TenantDetailsService } from '../service/TenantDetails';
import { TenantDetails } from '../model/TenantDetails';
import { AuthenticationDataService } from '../service/authentication-data.service';
import { HomeService } from '../service/home.service';
import { JwtCheckService } from '../service/jwt-check.service';
import { Home } from '../model/Home';
//import { marked } from 'angular-marked'
//import marked from 'marked';
@Component({
  selector: 'app-ask-for-help',
  templateUrl: './ask-for-help.component.html',
  styles: [
  ]
})

export class AskForHelpComponent implements OnInit {
  answer: string;
  question: string;
  subscription: Subscription;
  psubscription: Subscription;
  priority: string;
  tenantImage: TenantDetails;
  disableQuestion: boolean = true;
//  bsModalRef: BsModalRef;

  constructor(private askforhelpService: AskForHelpService,
    private authenticationdataservice: AuthenticationDataService,
    private homeService: HomeService,
    private jwtcheck: JwtCheckService,
    //private modalService: BsModalService,
    private data: DataService, private router: Router,
    private tenantDtlsSerivce: TenantDetailsService) {
  }

  ngOnInit(): void {
    this.subscription = this.data.currentMessage.subscribe(message => {
      //this.question = message; if (this.question != '')
      //this.question = message; if (this.question != '')
      var tmp = document.createElement("DIV");
      tmp.innerHTML = message;
      /*console.log('<---------------------------------remove html------------------',tmp.innerText)
      console.log('<---------------------------------test remove ------------------', tmp.textContent);
      this.question = message.replace("/(<([^>]+)>)/ig", ""); if (this.question != '')
       */
      this.question = tmp.innerText;
      if (this.question != '')
        this.save();
    });
    this.psubscription = this.data.currentPriority.subscribe(message => { this.priority = message; });
    this.tenantImage = this.tenantDtlsSerivce.get();

    this.jwtcheck.setToken(this.authenticationdataservice.get().access_token);
    // const response = this.userDetailService.validateUser(this.jwtcheck.getEmailId());
     
    //console.log(this.userName);
    // console.log(message);
    let email = this.jwtcheck.getEmailId();
    
    this.homeService.getHomeDetails(email).subscribe(

      (data: Home) => {
         
        ////debugger
      if (data.phase1Status == "Started" ||
          data.phase2Status == "Started" ||
          data.phase3Status == "Started") {
          this.disableQuestion = false;
        }

 
         
      }
    )
  }
  handlebacktoQuestion() {
    if (this.disableQuestion == false)
      this.router.navigate(['/questions']);
    else
      this.router.navigate(['/home']);
  }
 


    ticketPopup() {
      //this.save();
      //debugger;
      

      this.data.changePriority(this.priority);

      this.router.navigate(['/ticket'])
    }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

   
  save() {
  //  var md = marked.setOptions({});
    
    this.askforhelpService.getAnswer(this.question).subscribe(
      (data: any) => {
        ////debugger;md.parse(this.data);
        this.answer = data.reply;
      }, err => {
        var msg="";
        if(err.status==500){
          msg="Intenal server error"
        }
        else if(err.status==404){
          msg="Data Error"
        }
        else{
          msg=err.message
        }
        const initialState = {
          errormsg: msg
        };
        //console.log(msg);
        //this.bsModalRef = this.modalService.show(ErrorModalComponent, { initialState });
      }
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Answers,JourneyUserAnswer } from '../model/Questions';


@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  //model: Answers = new Answers();
  myAppUrl = '';
  constructor(private _http: HttpClient) {
    this.myAppUrl =environment.APIURL;
  }

  getQuestions(username:string) {
    //debugger;
    return this._http.get(this.myAppUrl + 'api/JourneyQuestions/'+username)
     
    .pipe(map(response => {
    //debugger;    
        return response
      }));

  }
  saveAnswer(answer: JourneyUserAnswer) {
//debugger
    //this.model.questionID = 123;
    //this.model.answers = new Array();
    //this.model.answers.push("asd");
    //console.log(this.model);
    
    return this._http.post(this.myAppUrl + 'api/JourneyAnswers', answer )
      .pipe(map(response => {
        return response;
      }));

  }



  //constructor(private _http: HttpClient) {
 
  //}

  //getQuestions() {

  //  return this._http.get(this.myAppUrl + 'api/Questions/GetQuestions')
  //    .pipe(map(response => {
  //      console.log(response)
  //      return response
  //    }));

  //}
}

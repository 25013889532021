// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  GraphURL: "https://graph.microsoft.com/v1.0/me",
  APIURL: "https://app-ja-backend-lin-dev-us.azurewebsites.net/",
  ClientID: "354b45e6-564b-4121-9b53-a2dac9d58faf",
  RedirectURL: "https://journeyautomationsofia.softel.com/admin/login",
  TenantID: "6215ebd7-b37d-4bc7-a032-1a2ea90dc0b9",
  APIResourceScope: "api://journeyautomationsofia.softel.com/354b45e6-564b-4121-9b53-a2dac9d58faf/read_access",
  //Authority: "https://login.microsoftonline.com/6215ebd7-b37d-4bc7-a032-1a2ea90dc0b9/",
  Authority: "https://login.microsoftonline.com/common/",
  Scope: "User.Read",
  tenantList: 'tenantsummary',
  tenantUserList: 'BatchUpload',
  addTenant: 'Tenant',
  getTenant: 'Tenant/',
  phaseType: 'TenantPhase/',
  getJourneyQuestionList: 'JourneyQuestionDefinition',
  migrationType: 'JourneyPath/',
  questionType: 'QuestionType',
  ticketPriorityData: 'QuestionPriority/',
  JourneyQuestionDefinition: 'JourneyQuestionDefinition',
  batchUsers: 'BatchUsers',
  batchPhase: 'BatchPhase',
  userTickets: 'UserTickets',
  testCall: 'JourneyUserTestCall',
  batchtestCall: 'BatchCall',
  voicetestcallBatch: 'VoiceTestCall',
  Guest: true
};



import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ErrorService } from '../service/ErrorService';

@Component({
  selector: 'app-authentication-error',
  templateUrl: './authentication-error.component.html',
  styleUrls: ['./authentication-error.component.scss']
})
export class AuthenticationErrorComponent implements OnInit {
  errorMessage: string;
  errorsubscription: Subscription;
  em:string;
  constructor(private errorService: ErrorService) { }

  ngOnInit(): void {
   // this.em='<div class="row"><div class="col-lg-12 col-12"><h4 class="pt-3">You will be able to access the Journey 3 weeks prior to your migration date </h4></div></div><div class="row"><div class="col-lg-12 col-12"><img src="https://stja.blob.core.windows.net/resources-public/CoPAWaves.png" height="500" width="600"/></div></div>';
   this.em='<b>this is bold</b>';
    this.errorsubscription = this.errorService.currentError.subscribe(message => {
      //debugger;
      if (message != "") {
        this.errorMessage = message;

      }

    });
  }

}

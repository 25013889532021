import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionInfo } from '../model/Questions';

@Component({
  selector: 'app-questions-child',
  templateUrl: './questions-child.component.html',
  styleUrls: ['./questions-child.component.scss']
})

export class QuestionsChildComponent implements OnInit {
  @Input() question:QuestionInfo;
  @Input() childanswer:string;
  @Input() questioncount:number;
  @Input() confirmtext:string;
  
  @Output() saveItemEvent = new EventEmitter<string>();
  @Output() backItemEvent = new EventEmitter<string>();
  optionsArray:string[]=[];
  dn1:string;
  dn2: string;
  selectplaceholder: string ="--Select--";
  areSame:boolean=false;
  istextinp:boolean=false;
  questiontext:string;
  confirmquestiontext:string;
  validationMessage:boolean=false;
  validationtext:string="The two locations provided do not match.  Please re-select the same location twice.";
  currentquestion:number;


  constructor() { }

  ngOnInit(): void {
    this.currentquestion=this.question.serialNumber-1;
    this.questiontext=this.question.question;
    this.confirmquestiontext=this.confirmtext;
    // this.confirmquestiontext="Please confirm "+this.question.question.replace("What is","");
    this.optionsArray = JSON.parse(this.question.answerOptions);
      //this.question.answerOptions.replace('[', '').replace(']', '').replace(/['"]+/g, '').split(',');
    if(this.childanswer!=null && this.childanswer!=""){
      if(this.optionsArray.indexOf(this.childanswer)>-1){
        this.dn1=this.childanswer;
        this.dn2 = this.childanswer;
         
      }
      else{
        this.dn1="Address not Found";
        this.dn2=this.childanswer;
        this.validationMessage=false;
        this.confirmquestiontext="";
        this.confirmquestiontext="Please Enter "+this.question.question.replace("What is","");      
        this.istextinp=true;
        this.areSame=this.dn2!=null && this.dn2!=""?true:false;     
  
      }
    }   
    
  }

  onchange(){
    if(this.dn1.toLowerCase()=="address not found"){
      this.validationMessage=false;
      this.confirmquestiontext="";
     // this.confirmquestiontext = "Please Enter " + this.question.question.replace("What is", "");
      this.confirmquestiontext = this.confirmtext.replace("confirm", "enter");
      this.dn2="";
      this.istextinp = true;
      this.areSame = this.dn2 != null && this.dn2 != ""  ? true : false;
      // this.validationMessage=this.dn2!=null && this.dn2!=""?false:true;
    }
    else
    {
      this.confirmquestiontext = this.confirmtext;
      this.istextinp=false;
      if (this.dn1 != this.dn2)
      {
        this.validationtext="";
        this.validationtext="The two locations provided do not match.  Please re-select the same location twice.";
        this.areSame=false;
        this.validationMessage=true;
      }
      else if (this.dn1 == "")
      {
        this.areSame = false;
      }
      else{
        this.areSame=true;
        this.validationMessage=false;
      }
    }
    
  }

  ontextchange(){
    this.validationtext=this.dn2!=null && this.dn2!=""?"":"Please Enter "+this.question.question.replace("What is","");
    this.validationMessage=this.dn2!=null && this.dn2!=""?false:true;
    this.areSame=this.dn2!=null && this.dn2!="" && this.dn2.trim()!=""?true:false;        
  }

  savechild(){
    this.saveItemEvent.emit(this.dn2);
  }
  backchild(){
    this.backItemEvent.emit(this.dn2);
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Answers, Questions, JourneyUserAnswer, QuestionInfo } from '../model/Questions';
import { QuestionsService } from '../service/questions.service';
//import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { UserDetailsService } from '../service/user-details.service';
import { Subscription } from 'rxjs';
import { DataService } from '../service/DataService';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { DatePipe } from '@angular/common';
import { TenantDetailsService } from '../service/TenantDetails';
import { TenantDetails } from '../model/TenantDetails';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {
  //@ViewChild('myModal') public myModal: ModalDirective;
  userName: string;
  answerArrayType: string[];
  questionsData: Questions;
  count: number;
  tempCount: number;
  currentQuestion: number;
  userResponse: any;
  totalQuestions: number;
  disableNext: boolean = false;
  journeyUserResponse: JourneyUserAnswer = new JourneyUserAnswer();
  answerVM: Answers = new Answers();
  tempResponse: any[]=[];
  checkboxArray:string[]=[];
  checkedArray: string[] = [];
  PriorityText: string[] = ["High", "Important", "Standard"];
  message: string;
  subscription: Subscription;
  tenantImage: TenantDetails;
  IsParentComponent:string="Yes";
  childQuestion:QuestionInfo=new QuestionInfo();
  //bsModalRef: BsModalRef;
  //,private modalService: BsModalService
  constructor(private datePipe: DatePipe, private router: Router, private questionService: QuestionsService, private http: HttpClient,
    private userDetailService: UserDetailsService, private data: DataService,
    private spinner: NgxSpinnerService,
    private tenantDtlsSerivce: TenantDetailsService) {
  }

  async ngOnInit(): Promise<void> {
    //debugger;
    this.spinner.show();
    this.tenantImage = this.tenantDtlsSerivce.get();
    this.subscription = this.data.currentMessage.subscribe(message => this.message = message);
    const res = await this.userDetailService.getProfile();
    this.userName = res.displayName;
    //this.userName = "Test";
    this.count = 0;
    this.tempCount = 0;
    //res.mail
    debugger;
    this.questionService.getQuestions(res.mail).subscribe(
      (data: Questions) => {
        
       // //debugger
        this.questionsData = data;
       // console.log("E911"+ this.questionsData.e911ConfirmationMessage)
        this.message = this.questionsData.completedMessage;
        //console.log(this.questionsData.answers[this.count].answerOptions);
        this.totalQuestions = this.questionsData.questions.length;
        this.currentQuestion = this.questionsData.questions.find(x => x.questionID == this.questionsData.currentQuestion).serialNumber - 1;
        this.count = this.currentQuestion;
        this.answerArrayType = JSON.parse(this.questionsData.questions[this.count].answerOptions);
          //.replace('[', '').replace(']', '').replace(/['"]+/g, '').split(',')
        
        //Check If Question Type Is Child
        if(this.questionsData.questions[this.count].questionTypeID==5){
          this.IsParentComponent="No";
          this.childQuestion=this.questionsData.questions[this.count];
        }
        this.spinner.hide();
        // this.userResponse=this.questionsData.answers[this.count].answerOptions;//404-data error 500-Internal server
      }, err => {
       // window.alert(JSON.stringify(err));
        var msg="";
        if(err.status==500){
          msg="Intenal server error"
        }
        else if(err.status==404){
          msg="Data Error"
        }        
        else{
          msg="Network Error"
        }
        const initialState = {
          errormsg: msg
        };
        this.spinner.hide();
        //console.log( msg)
       // this.bsModalRef = this.modalService.show(ErrorModalComponent, { initialState });
        //this.bsModalRef.content.closeBtnName = 'Close';
      }
    )
  }

  save() {
    debugger;
    this.spinner.show();
    if (this.tempResponse[this.tempCount] != undefined) {
      this.tempResponse[this.tempCount] = this.userResponse;
    }
    else {
      this.tempResponse.push(this.userResponse);
    }

  
    this.journeyUserResponse.userID = this.questionsData.userID;
    this.journeyUserResponse.journeyID = this.questionsData.journeyID;
    this.journeyUserResponse.tenantID = this.questionsData.tenantID;
    this.journeyUserResponse.phaseName = this.questionsData.phaseName;
    this.answerVM.answerOptions = this.userResponse;
    this.answerVM.questionID = this.questionsData.questions[this.count].questionID;
    this.answerVM.serialNumber = this.questionsData.questions[this.count].serialNumber;
    this.answerVM.answerTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    this.journeyUserResponse.answers = [];
    this.journeyUserResponse.answers.push(this.answerVM)


    this.disableNext = true;
   
    this.questionService.saveAnswer(this.journeyUserResponse).subscribe(
      (data: any) => {
        this.spinner.show();
        this.userResponse = null;
        this.count++;

        if (this.count < this.questionsData.questions.length) {
          this.answerArrayType = JSON.parse(this.questionsData.questions[this.count].answerOptions);
            //.replace('[', '').replace(']', '').replace(/['"]+/g, '').split(',')
        }
        if (this.tempResponse[this.tempCount + 1] != undefined) {
          this.userResponse = this.tempResponse[this.tempCount + 1];
        }
        this.tempCount++;
    debugger
        if(this.questionsData.questions[this.count]?.questionTypeID==5){
          this.IsParentComponent="No";
          this.childQuestion=this.questionsData.questions[this.count];
        }
    
        this.disableNext = false;
        this.spinner.hide();
      }, err => {
        this.disableNext = false;
        var msg="";
        if(err.status==500){
          msg="Intenal server error"
        }
        else if(err.status==404){
          msg="Data Error"
        }        
        else{
          msg="Network Error"
        }
        const initialState = {
          errormsg: msg
        };
       // console.log(msg);
        //this.bsModalRef = this.modalService.show(ErrorModalComponent, { initialState });
        //this.bsModalRef.content.closeBtnName = 'Close';
    });
 
    ////debugger

  }
  ok() {
    this.router.navigate(['/home'])
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  back() {
   // //debugger
    this.count--;
    this.tempCount--;
    this.userResponse = this.tempResponse[this.tempCount];
    this.answerArrayType = JSON.parse(this.questionsData.questions[this.count].answerOptions);
      //.replace('[', '').replace(']', '').replace(/['"]+/g, '').split(',');
    if(this.questionsData.questions[this.count].questionTypeID==5){
      this.IsParentComponent="No";
      this.childQuestion=this.questionsData.questions[this.count];
    }
  }

  helpPopup() {
    //this.save();
    ////debugger;
    let cnt = this.questionsData.questions[this.count].ticketPriority;
    this.data.changePriority(this.PriorityText[cnt - 1]);
    this.data.changeMessage(this.questionsData.questions[this.count].question);
 
    this.router.navigate(['/askforhelp'])
  }

  checkboxOnChange(value: string, isChecked: boolean) {
    if (isChecked) {
      this.checkboxArray.push(value);
    } else {
      let index = this.checkboxArray.findIndex(x => x == value)
      this.checkboxArray.splice(index, 1);
    }
    this.checkedArray = this.checkboxArray;
    this.userResponse = this.checkboxArray.toString();    
  }

  childoutput(answer:string){
    this.userResponse=answer;
    console.log("Child response"+this.userResponse);
    this.save();
    this.IsParentComponent="Yes";

  }

  childBack() {
    this.spinner.show();
    this.IsParentComponent="Yes";
    this.back();
    this.spinner.hide();

  }
}


import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorService } from './ErrorService';
 
 


@Injectable({
  providedIn: 'root'
})
export class HttperrordisplayService {

  constructor(private toastrs: ToastrService, private errorapi: ErrorService) {
   
  }

  public showError(message:string) {
    this.toastrs.error(message);
  }

  public showInformation(message: string) {
    this.toastrs.show(message);
  }

  public showSuccess(message: string) {
    this.toastrs.success(message);
  }

  public showWarming(message: string) {
    this.toastrs.warning(message);
  }

  // Handling HTTP Errors using Toaster
  public handleError(error: HttpErrorResponse) {
    
  //  this.toastrs.overlayContainer = this.toastContainer;
    let errorMessage = '';
 debugger;
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error: ${error.error.Message}`;
    }
   // window.alert(errorMessage);
    var msg = "";
    if (error.status == 500) {
      msg = "Intenal server error"
    }
    else if (error.status == 404) {
      errorMessage = "The Backend Service missing";
    }
    else {
      msg = "We are sorry but the site is experiencing technical difficulties" + errorMessage;
    }
  //  window.alert(msg);
    //debugger;
    this.errorapi.changeError(errorMessage);
    this.toastrs.error(errorMessage);

  }

}

import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [

  {
    title: true,
    name: 'Navigation Menu'
  },
  {
    name: 'Home',
    url: '/home',
    icon: 'icon-custom-home',

  },
  {
    name: 'Help',   
    icon: 'icon-custom-help',
    children: [
      {
        name: 'Ask for Help',
        url: '/askforhelp',        
      },
      {
        name: 'Create a Ticket',
        url: '/ticket',       
      },

    ]
  }, 
  {
    name: 'Personal Message',
    url: '/personalmessage',
    icon: 'icon-custom-personal-message'
  },  
];


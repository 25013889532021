import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { freeSet } from '@coreui/icons';

import { BrowserModule } from '@angular/platform-browser';
//import { HttpClient } from '@angular/common/http';

import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { filter, takeUntil } from 'rxjs/operators';

import { Subject } from 'rxjs';
import * as microsoftTeams from "@microsoft/teams-js";
import { environment } from '../environments/environment';
import * as Msal from 'msal';
import { StoreTokenService } from './service/store-token-service.service';
import { UserLoginService } from './service/user-login.service';
@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  isIframe = false;
  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();
  loginDisplay = false;
  constructor(
    private router: Router,
    public iconSet: IconSetService
  
  ) {
    // iconSet singleton
    iconSet.icons = { ...freeSet };
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }


  setLoginDisplay() {
    // this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }





  logout() {
    // this.authService.logout();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

export class Questions {
  tenantID: number;
  userID: number;
  phaseName: string;
  journeyID: number;
  currentQuestion: number;
  questions: QuestionInfo[];
  answers: Answers[];
  completedMessage: string="";
  e911ConfirmationMessage:string;
}

export class QuestionInfo {
  questionID: number;
  serialNumber: number;
  questionTypeID: number;
  question: string;
  answerOptions: string;
  ticketPriority: number;
}
export class Answers {
  questionID: number;
  serialNumber: number;
  answerOptions: string;
  answerTime: string;
}

export class JourneyUserAnswer {
  tenantID: number;
  userID: number;
  phaseName: string;
  journeyID: number;
  answers: Answers[]
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AskForHelpComponent } from './ask-for-help/ask-for-help.component';
import { AuthEndComponent } from './auth-end/auth-end.component';
import { AuthStartComponent } from './auth-start/auth-start.component';
import { AuthenticationErrorComponent } from './authentication-error/authentication-error.component';

// Import Containers
import { DefaultLayoutComponent } from './containers';
 
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { PersonalMessageComponent } from './personal-message/personal-message.component';
import { QuestionsComponent } from './questions/questions.component';
import { CustomAuthGuardService } from './service/custom-auth-guard.service';
import { TicketGuardService } from './service/ticket-guard';
import { TicketComponent } from './ticket/ticket.component';


export const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
    //redirectTo: 'home',
   // component: LoginComponent,
   // pathMatch: 'full',
  }, 
  
  {
    path: 'autherror',
    //canActivate: [MsalGuard],
    component: AuthenticationErrorComponent,
    data:{title:'Error'}
  }, 
  {
    path: 'auth-start',
    component: AuthStartComponent,
  }
  ,
  {
    path: 'auth-end',
    component: AuthEndComponent,
  },
  {
    path: '',
    component: DefaultLayoutComponent,
   // canActivateChild: [MsalGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'login',
        //redirectTo: 'home',
        component: LoginComponent,
      }
      ,
      {
        path: 'home',
        canActivate: [CustomAuthGuardService],
        component: HomeComponent,
        data:{title:'Home'}
      },      
      {
        path: 'askforhelp',
        canActivate: [CustomAuthGuardService],
        component: AskForHelpComponent,
        data: {
          title: 'Ask For Help'
        }
      },
      {
        path: 'questions',
        canActivate: [CustomAuthGuardService],
        component: QuestionsComponent,
        data: {
          title: 'Questions'
        }
      },
      {
        path: 'ticket',
        canActivate: [CustomAuthGuardService, TicketGuardService],
        component: TicketComponent,
        data: {
          title: 'Ticket'
        }
      },
      {
        path: 'personalmessage',
        canActivate: [CustomAuthGuardService],
        component: PersonalMessageComponent,
        data: {
          title: 'Personal Message'
        }
      },
      // {
      //   path: 'questionchild',
      //   canActivate: [CustomAuthGuardService],
      //   component: PersonalMessageComponent,
      //   data: {
      //     title: 'question child'
      //   }
      // },
     
    ]
  }
];
@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}

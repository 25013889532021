import { Injectable } from '@angular/core';
import { AuthToken } from '../model/AuthToken';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationDataService {
  auth: AuthToken;

  constructor() {
    this.auth = new AuthToken();
  }

  get(): AuthToken {
    return this.auth;
  }
  set(newvalue: AuthToken) {
   // console.log("-----new value-----", newvalue);
    this.auth = newvalue;
  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, DatePipe, CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule} from "ngx-toastr";
import { ToasterModule, ToasterService } from "angular2-toaster";
 


//import { HttpClient } from '@angular/common/http';





import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';


import { ProgressbarModule } from 'ngx-bootstrap/progressbar';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { StoreTokenService } from './service/store-token-service.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { AskForHelpComponent } from './ask-for-help/ask-for-help.component';
import { HomeComponent } from './home/home.component';
import { QuestionsComponent } from './questions/questions.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TicketComponent } from './ticket/ticket.component';
import { PersonalMessageComponent } from './personal-message/personal-message.component';
import { environment } from '../environments/environment';
import { AuthenticationErrorComponent } from './authentication-error/authentication-error.component';
import { JwtCheckService } from './service/jwt-check.service';
//import { AppHttpErrorInterceptor} from './app.http.Intercept';
 
import { AuthEndComponent } from './auth-end/auth-end.component';
import { AuthStartComponent } from './auth-start/auth-start.component';
import { LoginComponent } from './login/login.component';
import { MarkedPipe } from './marked.pipe';
import { HttperrordisplayService } from './service/httperrordisplay.service';
 
 
import { ErrorService } from './service/ErrorService';
import { QuestionsChildComponent } from './questions-child/questions-child.component';
import { CheckfortokenService } from './admin/checkfortoken.service';
import { AppHttpErrorInterceptor } from './app.http.Intercept';

//import * as auth from  './auth-config.json';
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;




@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    IconModule,
    FormsModule,
    IconSetModule.forRoot(),
    IconSetModule.forRoot(),
    HttpClientModule,
    ProgressbarModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    NgxSpinnerModule,
    TooltipModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-bottom-left",
    }),
    ToasterModule.forRoot()
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    AskForHelpComponent,
    HomeComponent,
    QuestionsComponent,
    TicketComponent,
    PersonalMessageComponent,
    AuthenticationErrorComponent,
    AuthEndComponent,
    AuthStartComponent,
    LoginComponent,
    MarkedPipe,
    QuestionsChildComponent

  ],
  providers: [

    IconSetService, StoreTokenService, JwtCheckService, HttperrordisplayService, ToasterService,
    { provide: HTTP_INTERCEPTORS, useClass: CheckfortokenService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpErrorInterceptor, multi: true },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AppHttpErrorInterceptor,
    //   multi: true
    // },
    IconSetService,
    DatePipe,
    ErrorService
  ],


  bootstrap: [AppComponent]
})
export class AppModule { }

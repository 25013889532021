import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TicketAnswer } from '../model/Ticket';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
//import { throwError } from 'rxjs/internal/observable/throwError';
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class TicketService {
  myAppUrl = '';
  constructor(private _http:HttpClient) {
    this.myAppUrl = environment.APIURL;
   }
  save(answer:TicketAnswer,file:File){   
    //debugger
     // Create form data 
     let headers = new HttpHeaders({
      'Content-Type':'multipart/form-data'
     }
      );
  let options = { headers: headers };
     const formData = new FormData();  
      //formData.append("value",answer);
     // Store form name as "file" with file data 
     //formData.append("model", angular.toJson(answer));
     formData.append("Data",JSON.stringify(answer)); 

    if (file != null)
      formData.append("file", file, file.name);



  return this._http.post(this.myAppUrl+"api/UserServiceRequest",formData)
    .pipe(map(response => {
      return response
    }), catchError(err=> of([])));
  }
}

import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';
import { JwtCheckService } from '../service/jwt-check.service';
import { StoreTokenService } from '../service/store-token-service.service';
import { UserLoginService } from '../service/user-login.service';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from '../service/ErrorService';
import { JWTTokenService } from '../service/JWTTokenService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  tokenSubscription: Subscription;
  errorsubscription: Subscription;
  insideSubs:boolean=false;
  constructor(private toastr: ToastrService, private spinner: NgxSpinnerService,
    private store: StoreTokenService,
    private userservice: UserLoginService,
     private router: Router,
    private errorfromapi: ErrorService,
    private jwtToken: JWTTokenService,private ngZone:NgZone
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.store.remove('auth');
    //debugger;
    this.tokenSubscription = this.jwtToken.currentToken.subscribe(token => {
      //debugger;
      if (token != null) {
        //debugger;
         //window.alert("---ngoninit login----");
        //window.alert(message);
        this.spinner.hide();
        //window.alert("--------------------------home----------------");
      //  window.alert(this.router.url);
        //this.router.navigateByUrl('/home');
        this.insideSubs = true;
        this.ngZone.run(()=>this.router.navigateByUrl("/home"));
       // return;
        //this.router.navigate(['/home']);

        //console.log("_______________________________________");
        //console.log(message);
        //console.log("_______________________________________");

      }

    });

    this.errorsubscription = this.errorfromapi.currentError.subscribe(message => {
      if (message != "") {
        this.spinner.hide();
        //console.log("_______________________________________");
        //console.log(message);
        //console.log("_______________________________________");

      }

    });
    if(this.insideSubs==false)
      this.login();
  }
  async login() {
    //window.alert("login");
    //debugger;
    this.userservice.Login();
  }

  ngOnDestroy() {
    this.tokenSubscription.unsubscribe();
    //this.errorsubscription.unsubscribe();
  }

}

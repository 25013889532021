import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckfortokenService implements HttpInterceptor {
  constructor() {}

  intercept( req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   //console.log("<<<-----------------------------NEW Intercept-------------------------->",req.url);
  return next.handle(req);
  }
}
